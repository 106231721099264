<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import DataForm from "./dataFormCustomer";
import { FormInstance } from "element-plus";
import { useRouter, useRoute } from "vue-router";
const rolesAccount = ref("");

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import toastr from "toastr";
import Swal from "sweetalert2";
import { CustomerStore } from "../../stores/getCustomer";
import LanguageService from "../../service/LanguageService";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
const customerStore = CustomerStore();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const dataService = reactive({ value: [] });
let loading = ref(true); // loading bảng
const router = useRouter();
const route = useRoute();
const listTableName = reactive({ value: [] });
const listIdentity = ref([
  {
    value: "true",
    name:
      LanguageService[localStorage.getItem("language").toLocaleUpperCase()]
        ?.t_yes ?? "Yes",
  },
  {
    value: "false",
    name:
      LanguageService[localStorage.getItem("language").toLocaleUpperCase()]
        ?.t_no ?? "No",
  },
]);

// function
// lấy về danh sách
const getCustomer = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields:
      "id,type,service_id,service_position,col_name,col_position,field_name,table_config_id,where_statement,thresh_check,disabled,search_enable,is_identity,sortable,default_sort_type",
    limit: 999,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "CustomerSummary",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});

  try {
    const response = await mushroom.customer_summary.listAsync(dataFilter, {
      enabledCache: false,
    });
    console.log("Các customer_summary thỏa mãn: %o, info: %o", response);
    if (response.result) {
      loading.value = false;
      let res = await changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

const changeData = async (data) => {
  const item = data.filter((x) => x.service_id).map((x) => x.service_id);

  if (item.length > 0) {
    try {
      const response = await mushroom.service.listAsync({
        filters: "id:in:" + item.join(","),
      });
      if (response.result) {
        data.forEach((service) => {
          if (service.service_id) {
            response.result.forEach((x) => {
              if (x.id === service.service_id) {
                service.service_name = x.name;
              }
            });
          }
        });
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }

  const tableId = data
    .filter((x) => x.table_config_id)
    .map((x) => x.table_config_id);

  if (tableId.length > 0) {
    try {
      const response = await mushroom.table_config.listAsync({
        filters: "id:in:" + tableId.join(","),
      });
      if (response.result) {
        data.forEach((customer) => {
          if (customer.table_config_id) {
            response.result.forEach((x) => {
              if (x.id === customer.table_config_id) {
                customer.table_config_name = x.name;
              }
            });
          }
        });
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }

  return data;
};

const getAllTableConfigs = async (idService) => {
  try {
    if (!idService) {
      const resAllTableConfigs = await mushroom.table_config.getAllAsync(
        { limit: 100 },
        {
          enabledCache: false,
        }
      );
      if (resAllTableConfigs.result?.length > 0) {
        listTableName.value = [];
        listTableName.value = resAllTableConfigs.result.map((x) => {
          return {
            id: x.id,
            name: x.name,
          };
        });
      }
    } else {
      const resTableConfigsFilterByServiceID =
        await mushroom.table_config.listAsync({
          fields: "id,name,split_type,service_id",
          filters: ["service_id=" + idService],
          limit: 999,
        });
      if (resTableConfigsFilterByServiceID.result?.length > 0) {
        listTableName.value = [];
        listTableName.value = resTableConfigsFilterByServiceID.result.map(
          (x) => {
            return {
              id: x.id,
              name: x.name,
            };
          }
        );
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const changeService = async (idService) => {
  tableRules.dataSearch.value["table_config_id"] = "";
  await getAllTableConfigs(idService);
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "CustomerSummary",
    query: query,
  });
};

// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      tableRules.dataSearch.value["col_name"] = tableRules.dataSearch.value[
        "col_name"
      ]
        ? tableRules.dataSearch.value["col_name"].trim()
        : "";
      tableRules.dataSearch.value["field_name"] = tableRules.dataSearch.value[
        "field_name"
      ]
        ? tableRules.dataSearch.value["field_name"].trim()
        : "";

      // handleService();

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.page = 1;
      getCustomer();
    } else return false;
  });
};

const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  getCustomer();
};

const handleClick = (id) => {
  router.push({
    name: "UpdateCustomer",
    query: { id: id },
  });
};

const fn_CreateCustomer = () => {
  router.push({ name: "CreateCustomer" });
};

const deleteCustomer = async (id) => {
  if (!id) return;
  Swal.fire({
    title: t("t_warning"),
    text: t("t_customer_delete"),
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    confirmButtonText: t("swal_accept"),
    cancelButtonText: t("swal_cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await mushroom.customer_summary.deleteAsync({
          id: id, // required
        });
        console.log("Thông tin customer_summary trả về: %o", response);
        toastr.success(t("toastr_delete_customer_success"));
        getCustomer();
        customerStore.getCustomerSummary();
      } catch (e) {
        toastr.error(t("toastr_delete_customer_fail"));
        console.error("Có lỗi: %o", e);
      }
    }
  });
};

const fn_hashUrl = () => {
  if (route.query.limit) {
    tableRules.limit = parseInt(route.query.limit);
  }
  if (route.query.page) {
    tableRules.page = parseInt(route.query.page);
  }
  if (route.query.limit && route.query.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (route.query.sort) {
    tableRules.sort = route.query.sort;
    tableRules.defaultSort[0] = tableRules.sort;
  }
  if (tableRules.sort.indexOf("-") != -1) {
    tableRules.defaultSort[1] = "descending";
  } else {
    tableRules.defaultSort[1] = "ascending";
  }
  if (route.query.filters) {
    tableRules.filters = route.query.filters;
    // string to object
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  if (route.query.showFormSearch) {
    tableRules.showFormSearch = route.query.showFormSearch;
  }
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();

    listIdentity.value = [
      {
        value: "true",
        name: LanguageService[newLang.toLocaleUpperCase()]?.t_yes ?? "Yes",
      },
      {
        value: "false",
        name: LanguageService[newLang.toLocaleUpperCase()]?.t_no ?? "No",
      },
    ];
  }
);

onMounted(async () => {
  fn_hashUrl();
  const response = await mushroom.$auth.meAsync();
  rolesAccount.value = response.result.roles[0];
  await customerStore.getCustomerSummary();
  await customerStore.getService();
  dataService.value = customerStore.showListService;

  await getCustomer();
  const obj = { ...tableRules.dataSearch.value };
  if ("service_id" in obj) {
    await getAllTableConfigs(obj["service_id"]);
  } else await getAllTableConfigs();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div
        class="card-header"
        style="display: flex; justify-content: space-between"
      >
        <h3 class="text-uppercase mb-0">{{ $t("t-khach-hang") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              style="margin-right: 8px"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_CreateCustomer"
              v-if="rolesAccount === 'Admin'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  ref="ruleFormRef"
                  :model="tableRules.dataSearch.value"
                  :rules="validForm.value"
                  label-position="top"
                  @submit.prevent
                >
                  <b-row>
                    <b-col md="4">
                      <el-form-item
                        :label="$t('t-field-name')"
                        prop="field_name"
                      >
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['field_name']"
                          :placeholder="$t('t-place-field-name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>

                    <b-col md="4">
                      <el-form-item :label="$t('t-service')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['service_id']"
                          clearable
                          :placeholder="$t('t-select-service')"
                          @change="changeService"
                        >
                          <el-option
                            v-for="item in dataService.value"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>

                    <b-col md="4">
                      <el-form-item
                        :label="$t('t-table-config-title_name')"
                        prop="col_name"
                      >
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['col_name']"
                          :placeholder="$t('t-place-table-config-title_name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>

                    <b-col md="4">
                      <el-form-item :label="$t('t-table-name')">
                        <el-select
                          filterable
                          v-model="
                            tableRules.dataSearch.value['table_config_id']
                          "
                          clearable
                          :placeholder="$t('t-select-table')"
                        >
                          <el-option
                            v-for="item in listTableName.value"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>

                    <b-col md="4">
                      <el-form-item :label="$t('t-type')">
                        <el-select
                          v-model="tableRules.dataSearch.value['type']"
                          clearable
                          :placeholder="$t('t-select-type')"
                        >
                          <el-option
                            v-for="item in customerStore.listType"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>

                    <b-col md="4">
                      <el-form-item :label="$t('t-identify')">
                        <el-select
                          v-model="tableRules.dataSearch.value['is_identity']"
                          clearable
                          :placeholder="$t('t-select-identify')"
                        >
                          <el-option
                            v-for="item in listIdentity"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem(ruleFormRef)"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <el-table
            size="small"
            border
            stripe
            :data="dataTables.value"
            style="width: 100%"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{ prop: 'type', order: 'descending' }"
          >
            <el-table-column
              :label="$t('t-stt')"
              width="60"
              align="center"
              fixed
            >
              <template #default="scope">
                <div>
                  {{ scope.$index + tableRules.offset + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="field_name"
              :label="$t('t-field-name')"
              align="center"
              min-width="180"
              fixed
              :sortable="true"
            ></el-table-column>
            <el-table-column
              prop="service_name"
              :label="$t('t-service-name')"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="service_position"
              :label="$t('t-service-index')"
              align="center"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="col_name"
              :label="$t('t-table-config-title_name')"
              align="center"
              min-width="140"
              :sortable="true"
            ></el-table-column>
            <el-table-column
              prop="col_position"
              :label="$t('t-index')"
              align="center"
              width="120"
              :sortable="true"
            ></el-table-column>
            <el-table-column
              prop="table_config_name"
              :label="$t('t-table-name')"
              min-width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="type"
              :label="$t('t-type')"
              width="110"
              align="center"
              :sortable="true"
            ></el-table-column>
            <!-- <el-table-column
                prop="is_identity"
                label="Định danh"
                width="110"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.is_identity"
                    class="badge badge-soft-success text-uppercase"
                    >Có</span
                  >
                  <span
                    v-if="scope.row.is_identity === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    Không
                  </span>
                </template>
              </el-table-column> -->
            <!-- <el-table-column
                prop="thresh_check"
                label="Kiểm tra ngưỡng"
                width="150"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.thresh_check"
                    class="badge badge-soft-success text-uppercase"
                    >Có</span
                  >
                  <span
                    v-if="scope.row.thresh_check === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    Không
                  </span>
                </template>
              </el-table-column> -->
            <!-- <el-table-column
                prop="disabled"
                label="Disabled"
                align="center"
                width="100"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.disabled"
                    class="badge badge-soft-success text-uppercase"
                    >Có</span
                  >
                  <span
                    v-if="scope.row.disabled === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    Không
                  </span>
                </template>
              </el-table-column> -->
            <!-- <el-table-column
                prop="search_enable"
                label="Cho tìm kiếm"
                width="120"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.search_enable"
                    class="badge badge-soft-success text-uppercase"
                    >Có</span
                  >
                  <span
                    v-if="scope.row.search_enable === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    Không
                  </span>
                </template>
              </el-table-column> -->
            <!-- <el-table-column
                prop="sortable"
                label="Cho sắp xếp"
                width="120"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.sortable"
                    class="badge badge-soft-success text-uppercase"
                    >Có</span
                  >
                  <span
                    v-if="scope.row.sortable === false"
                    class="badge badge-soft-danger text-uppercase"
                  >
                    Không
                  </span>
                </template>
              </el-table-column> -->
            <!-- <el-table-column
                prop="default_sort_type"
                label="Sắp xếp"
                width="100"
                align="center"
              ></el-table-column> -->
            <el-table-column
              :label="$t('t-show-district')"
              align="center"
              width="100"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    :content="$t('t-edit')"
                    effect="light"
                    placement="top"
                    :enterable="false"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="handleClick(scope.row.id)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('t-delete')"
                    effect="light"
                    placement="top"
                    :enterable="false"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="deleteCustomer(scope.row.id)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
a {
  color: unset;
}
.el-table {
  height: calc(100vh - 200px);
}
</style>
